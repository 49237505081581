import React from "react";
import PropTypes from "prop-types";

// See https://www.joshwcomeau.com/performance/embracing-modern-image-formats/
// If browser supports it, will show src in modern format; else shows fallback.
// Modern formats include WebP and AVIF

const ImgWithFallback = (props) => {
  const { alt, fallback, src, type, ...rest } = props;
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} alt={alt} {...rest} />
    </picture>
  );
};

ImgWithFallback.propTypes = {
  alt: PropTypes.string,
  fallback: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
};

ImgWithFallback.defaultProps = {
  alt: "",
  type: "image/webp",
};

export default ImgWithFallback;
