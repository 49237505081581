import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useLocalStorageState from "use-local-storage-state";
import { makeStyles } from "@material-ui/core/styles";
import ImgWithFallback from "./imgWithFallback";

// These two meme arrays need to be the same images, just different formats.
const pngMemes = [
  "https://fl-react.s3-us-west-1.amazonaws.com/login-meme-01-4x-20230131.png",
  "https://fl-react.s3-us-west-1.amazonaws.com/login-meme-02-2x-20230131.png",
];

const webpMemes = [
  "https://fl-react.s3-us-west-1.amazonaws.com/login-meme-01-4x-20230131.webp",
  "https://fl-react.s3-us-west-1.amazonaws.com/login-meme-02-2x-20230131.webp",
];

const useStyles = makeStyles(() => ({
  memeContainer: {
    position: "absolute",
    width: (props) => props.height,
  },
  memeImage: {
    height: "100vh",
    position: "fixed",
    top: 0,
    right: 0,
  },
}));

const Meme = ({ height, memeIsVisible }) => {
  const classes = useStyles({ height });
  const [imageError, setImageError] = useState(0);
  const [memeIndex, setMemeIndex] = useLocalStorageState("memeIndex", {
    defaultValue: -1,
  });

  const incrementMemeIndex = () => {
    setMemeIndex((memeIndex) => (memeIndex + 1) % pngMemes.length);
  };

  // run once on mount
  useEffect(() => {
    incrementMemeIndex();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if an image fails to load, increment index to try again.
  // try as many times as the length of the memes array.
  const handleError = (event) => {
    if (imageError < pngMemes.length) {
      incrementMemeIndex();
    }
    setImageError((prevValue) => prevValue + 1);
  };

  if (!memeIsVisible) {
    return null;
  }

  return (
    <div className={classes.memeContainer}>
      <ImgWithFallback
        className={classes.memeImage}
        fallback={pngMemes[memeIndex]}
        onError={handleError}
        src={webpMemes[memeIndex]}
      />
    </div>
  );
};

Meme.propTypes = {
  height: PropTypes.number,
  memeIsVisible: PropTypes.bool,
};

export default Meme;
