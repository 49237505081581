import React, { useEffect, useState } from "react";
import _ from "lodash";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginForm from "./login_form";
import Meme from "./meme";
import useStyles from "./styles";

const alertHeight = 76;
const circDiameter = 64;
const circRadius = circDiameter / 2;
const circThickness = 4;
const outerFormWidth = 420;
const scrollbarSize = 15;
const typicalDimensions = {
  h: 723,
  w: 1276,
};

const getWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";
  const root = document.getElementById("root") || null;
  const hasHorizontalScrollbar = root.scrollWidth > root.clientWidth;
  const windowWidth = hasWindow ? window.innerWidth : null;
  const windowHeight = hasWindow
    ? hasHorizontalScrollbar
      ? window.innerHeight - scrollbarSize
      : window.innerHeight
    : null;
  return {
    windowWidth,
    windowHeight,
  };
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [windowHeight, setWindowHeight] = useState(typicalDimensions.h);
  const [windowWidth, setWindowWidth] = useState(typicalDimensions.w);
  const [widthForForm, setWidthForForm] = useState(typicalDimensions.w);
  const [widthForMeme, setWidthForMeme] = useState(typicalDimensions.h);
  const [memeIsVisible, setMemeIsVisible] = useState(false);

  const classes = useStyles({ widthForForm, widthForMeme, windowWidth });

  const updateWindowDimensions = () => {
    const { windowHeight: h, windowWidth: w } = getWindowDimensions();
    if (h && w) {
      setWidthForForm(w - h);
      setWidthForMeme(h); // meme is square
      setWindowHeight(h);
      setWindowWidth(w);
    }
  };

  useEffect(() => {
    const throttledUpdateWindowDimensions = _.throttle(
      updateWindowDimensions,
      500
    );
    throttledUpdateWindowDimensions();
    window.addEventListener("resize", throttledUpdateWindowDimensions);
    return () => {
      window.removeEventListener("resize", throttledUpdateWindowDimensions);
    };
  }, []); // empty array ensures that the effect is only run once

  useEffect(() => {
    const widthImage = windowHeight; // meme is square
    const thereIsRoomForBoth = windowWidth > outerFormWidth + windowHeight;
    let widthForm, visible;
    if (thereIsRoomForBoth) {
      widthForm = windowWidth - widthImage;
      visible = true;
      setWidthForMeme(widthImage);
    } else {
      widthForm = windowWidth;
      visible = false;
      setWidthForMeme(0);
    }
    setWidthForForm(widthForm);
    setMemeIsVisible(visible);
  }, [windowHeight, windowWidth]);

  // django messages
  useEffect(() => {
    const getMessages = (messagesEl) => {
      const childNodesArray = Array.from(messagesEl.children);
      let messagesArray = [];
      childNodesArray.forEach((child) => {
        const message = child.querySelector("#id_message").innerText;
        const message_tags = child.querySelector("#id_message_tags").innerText;
        messagesArray.push({ message, tags: message_tags });
      });
      return messagesArray;
    };

    const messagesEl = document.getElementById("id_messages");
    if (messagesEl) {
      const messagesArray = getMessages(messagesEl);
      setMessages(messagesArray);
    } else {
      setMessages([]);
      const o = new MutationObserver((mutations) => {
        for (let mutation of mutations) {
          if (mutation.type === "childList") {
            const messagesEl = document.getElementById("id_messages");
            if (messagesEl) {
              const messagesArray = getMessages(messagesEl);
              setMessages(messagesArray);
            } else {
              setMessages([]);
            }
          }
        }
      });
      o.observe(document, {
        childList: true,
      });
    }
  }, []);

  return (
    <div className={classes.container}>
      {messages.map((message, index) => {
        const { tags, message: msg } = message;
        return (
          <Alert
            action={null}
            classes={{
              message: classes.alertMessage,
              root: classes.alertRoot,
              standardInfo: classes.standardInfo,
            }}
            className={classes.alert}
            icon={false}
            key={index}
            onClose={() => {
              setMessages((prevMessages) => {
                // remove message number `index` from messages
                return prevMessages.filter((_, i) => i !== index);
              });
            }}
            severity={tags || "info"}
            variant="standard"
          >
            {msg}
          </Alert>
        );
      })}
      <div
        style={{
          height: `calc(100vh - ${alertHeight * messages.length}px)`,
          width: widthForForm,
        }}
      >
        <div className={classes.form}>
          <LoginForm setLoading={setLoading} />
        </div>
        <Meme height={widthForMeme} memeIsVisible={memeIsVisible} />
      </div>
      <Backdrop
        classes={{
          root: classes.backdrop,
        }}
        onClick={() => setLoading(false)}
        open={loading}
      >
        <div
          style={{
            transform: `translate(-${circRadius}px, -${circRadius}px)`,
          }}
        >
          <div style={{ position: "absolute" }}>
            <CircularProgress
              className={classes.circularProgressStationary}
              size={circDiameter}
              thickness={circThickness}
              value={100}
              variant="determinate"
            />
          </div>
          <div style={{ position: "absolute" }}>
            <CircularProgress
              className={classes.circularProgressMoving}
              size={circDiameter}
              thickness={circThickness}
            />
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default Login;
