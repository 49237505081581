import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// legacy colors with comment showing color name in `spectrum`
const spectrum_legacy = {
  red: "#C93524", // crimson
  orange: "#F7931E", // fire
  // yellow: "#CCD64C", // no longer used
  green: "#62B146", // kiwi
  brightGreen: "#6CC72B", // lime
  darkGreen: "#009245", // darkGreen
  darkTeal: "#369182", // darkTeal
  teal: "#48C0BF", // islay
  gray: "#CCCCCC", // gray
  inverse: "#2D3C51", // denim
  blue: "#31A0DF", // blue
  white: "#FFFFFF", // white
};

const spectrum = {
  // black
  black: "#000000",
  // white
  white: "#FFFFFF",
  ivory: "#FAFAFA",
  // green
  lime: "#6CC72B", // bright
  kiwi: "#62B146", // less bright
  sage: "#C3E1B8", // light
  darkGreen: "#009245",
  // red
  fire: "#F7931E", // orange
  crimson: "#C93524", // red
  darkRed: "#B11C0B",
  darkMango: "#E4B200",
  mango: "#F7C61A",
  // blue
  denim: "#2D3C51", // dark
  blue: "#31A0DF",
  yale: "#345173", // medium
  azure: "#0075BF", // bright
  islay: "#48C0BF", // teal,
  darkDenim: "#243040",
  darkTeal: "#369182",
  // grey
  cloud: "#E6E5EC", // light
  gray: "#CCCCCC",
  steel: "#9DB2C3", // medium
  slate: "#676767", // dark
  darkSlate: "#525252",
};

const palette = {
  text: {
    primary: spectrum.black,
    secondary: spectrum.slate,
    inverse: spectrum.white,
    disabled: spectrum.steel,
    hint: spectrum.steel,
  },
  primary: {
    main: spectrum.kiwi, // for text contrast
    brand: spectrum.lime, // for non-text elements
    contrastText: spectrum.white,
  },
  secondary: {
    main: spectrum.azure,
    contrastText: spectrum.white,
  },
  info: {
    main: spectrum.steel,
    secondary: spectrum.islay,
    contrastText: spectrum.white,
  },
  success: {
    main: spectrum.lime,
    contrastText: spectrum.white,
  },
  warning: {
    main: spectrum.fire,
    contrastText: spectrum.white,
  },
  error: {
    main: spectrum.crimson,
    contrastText: spectrum.white,
  },
  background: {
    paper: spectrum.white,
    default: spectrum.white,
    inverse: spectrum.denim,
  },
  grey: {
    light: spectrum.cloud,
    medium: spectrum.steel,
    dark: spectrum.slate,
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1024,
      lg: 1400,
      xl: 1920,
    },
  },
  palette: {
    ...palette,
    // legacy values...
    metric: {
      directionUp: "#62b146",
      directionDown: "#b43236",
    },
    spectrum: {
      ...spectrum_legacy,
      ...spectrum,
    },
    charts: [
      spectrum_legacy.teal,
      spectrum_legacy.green,
      spectrum_legacy.darkGreen,
      spectrum_legacy.darkTeal,
      spectrum_legacy.red,
      spectrum_legacy.orange,
      spectrum_legacy.gray,
      spectrum_legacy.inverse,
    ],
  },
  overrides: {
    MuiAlert: {
      filledSuccess: {
        backgroundColor: spectrum.kiwi,
      },
      outlinedSuccess: {
        backgroundColor: spectrum.kiwi,
      },
      standardSuccess: {
        backgroundColor: spectrum.kiwi,
      },
    },
    MuiButton: {
      outlined: {
        boxShadow: "0px 3px 3px -1px rgba(0,0,0,0.25)",
      },
      outlinedPrimary: {
        borderColor: spectrum.kiwi,
        borderWidth: 2,
        "&:hover": {
          borderWidth: 2,
        },
      },
      outlinedSecondary: {
        borderColor: spectrum.azure,
        borderWidth: 2,
        "&:hover": {
          borderWidth: 2,
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid rgb(0,0,0,0.12)",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "1px solid rgb(0,0,0,0.12)",
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: palette.info.main,
        "&$checked": {
          color: palette.info.contrastText,
        },
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "collapse",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple on the whole application
    },
  },
});

const FreshLimeThemeProvider = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};

FreshLimeThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default FreshLimeThemeProvider;
