import { lighten, makeStyles } from "@material-ui/core/styles";
import bgLocal from "./images/interact-bg.svg";

let bg = bgLocal;

if (window.location.hostname.includes("stage.freshlime.com")) {
  bg = "https://freshlime-stage.s3.amazonaws.com/static/fluser/static/media/interact-bg.c16e90ff.svg";
} else if (
  window.location.hostname.includes("app.freshlime.com") ||
  window.location.hostname.includes("int.freshlime.com")
) {
  bg = "https://freshlime.s3.amazonaws.com/static/fluser/static/media/interact-bg.c16e90ff.svg";
}

// const containerWidth = 840;
const outerFormWidth = 420;
// const formWidth = 344;
// const inputWidth = 328;

const useStyles = makeStyles((theme) => ({
  // WRAPPING ELEMENTS
  container: {
    backgroundImage: `url(${bg})`,
    margin: 0,
    padding: 0,
  },
  form: {
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    width: outerFormWidth,
  },
  // MISC
  alertMessage: {
    fontSize: "1.2rem",
    marginLeft: "auto",
    marginRight: 0,
  },
  alertRoot: {
    width: (props) => props.widthForForm,
  },
  standardInfo: {
    backgroundColor: lighten("#D2EAFF", 0.6),
    color: lighten(theme.palette.spectrum.black, 0.6),
  },
  backdrop: {
    backgroundColor: "rgba(256, 256, 256, 0.3)",
    right: (props) => (props.widthForMeme ? props.widthForMeme : 0),
    zIndex: theme.zIndex.drawer + 1,
  },
  circularProgressMoving: {
    color: theme.palette.spectrum.cloud,
  },
  circularProgressStationary: {
    color: theme.palette.spectrum.gray,
  },
}));

export default useStyles;
