import React from 'react';
import Login from "./pages/user/login"
import ThemeProvider from "./providers/theme.jsx";

function App() {
  return (
    <ThemeProvider>
      <Login />
    </ThemeProvider>
  );
}

export default App;
