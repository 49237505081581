import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    "& > $stackItem:first-child": {
      marginLeft: (props) => props.spacing,
    },
    "& > $stackItem:last-child": {
      marginRight: (props) => props.spacing,
    },
  },
  stackItem: {
    display: "flex",
    marginRight: (props) => props.spacing / 2,
    marginLeft: (props) => props.spacing / 2,
  },
}));

const HorizontalStack = React.forwardRef(function VerticalStack(props, ref) {
  const { align, children, className, spacing = 0, ...rest } = props;
  const classes = useStyles({ spacing });

  return (
    <Box
      alignItems={align}
      className={clsx(classes.stackRoot, className)}
      ref={ref}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        return <Box className={classes.stackItem}>{child}</Box>;
      })}
    </Box>
  );
});

HorizontalStack.propTypes = {
  align: PropTypes.oneOf(["flex-start", "center", "flex-end"]), // TODO: remove? duplicate of alignItems?
  children: PropTypes.node,
  className: PropTypes.string,
  spacing: PropTypes.number, // number of pixels
};

export default HorizontalStack;
