import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 14,
    marginLeft: 16,
    marginRight: 16,
    width: 88,
  },
  top: {
    borderBottom: "2px dotted",
    borderBottomColor: theme.palette.spectrum.gray,
    height: 6,
  },
  bottom: {
    height: 6,
  },
}));

const DashedLine = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      className={classes.root}
    >
      <Box className={classes.top} />
      <Box className={classes.bottom} />
    </Box>
  );
};

export default DashedLine;
