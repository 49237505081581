import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useMount } from "react-use";
import isEmail from "validator/es/lib/isEmail";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Logo } from "./images/freshlime-logo.svg";
import { ReactComponent as Convenient } from "./images/convenient-customer.svg";
import { ReactComponent as Welcome } from "./images/big-green-welcome.svg";
import DashedLine from "./images/dashed-line.jsx";
// import { ReactComponent as Apple } from "./images/icon_apple.svg";
import { ReactComponent as Facebook } from "./images/icon_facebook.svg";
import { ReactComponent as Google } from "./images/icon_google.svg";
import useStyles from "./styles.form";
import HorizontalStack from "../../components/stack_horizontal";
import VerticalStack from "../../components/stack_vertical";

const LoginForm = ({ setLoading }) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [showEmailClear, setShowEmailClear] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [serverError, setServerError] = useState("");
  const [localEmailError, setLocalEmailError] = useState("");

  const [showEndAdornments, setShowEndAdornments] = useState(false);
  const [passwordManagerDetected, setPasswordManagerDetected] = useState("");
  const [isFirstClick, setIsFirstClick] = useState(true);

  const [mode, setMode] = useState("login"); // login, reset, sent

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  // `serverError` is django's errorMessages from server, outside of React
  // Check immediately and then observe for changes. TODO: both needed?
  useEffect(() => {
    const messageEl = document.getElementById("id_error_message");
    if (messageEl) {
      setServerError(messageEl.innerText);
    } else {
      setServerError("");
    }
    const o = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (mutation.type === "childList") {
          const messageEl = document.getElementById("id_error_message");
          if (messageEl) {
            setServerError(messageEl.innerText);
          } else {
            setServerError("");
          }
        }
      }
    });
    o.observe(document, {
      childList: true,
    });
    return () => {
      o.disconnect();
    };
  }, []);

  const checkForPasswordManagers = () => {
    // outside of React tree
    const onePassword = document.querySelector("com-1password-button");
    if (onePassword) {
      setPasswordManagerDetected("onepassword");
    }
    // in the React form
    const keeper = document.querySelector("keeper-lock");
    if (keeper) {
      setPasswordManagerDetected("keeper");
    }
    const autofill = document.querySelectorAll("input:-webkit-autofill");
    if (autofill.length > 0) {
      setPasswordManagerDetected("autofill");
    }
  };

  // on mount, 1 second later and (in onClick) on first click in empty fields
  useMount(() => {
    checkForPasswordManagers();
    setTimeout(checkForPasswordManagers, 1000);
  });

  // detect changes to dom element style
  useEffect(() => {
    const o = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (mutation.type === "attributes") {
          // LastPass will make many changes to style, but backgroundImage is enough
          const lastPass = mutation.target.style.backgroundImage;
          if (lastPass) {
            setPasswordManagerDetected("lastpass");
          }
          const onePassword =
            mutation.target.attributes["data-com-onepassword-filled"];
          if (onePassword) {
            setPasswordManagerDetected("onepassword");
          }
          const keeper = mutation.target.attributes["data-keeper-lock-id"];
          if (keeper) {
            setPasswordManagerDetected("keeper");
          }
          const dashlane = mutation.target.attributes["data-dashlane-rid"];
          if (dashlane) {
            setPasswordManagerDetected("dashlane");
          }
          const autofill = mutation.target.attributes[":-webkit-autofill"];
          if (autofill) {
            setPasswordManagerDetected("autofill");
          }
        }
      }
    });
    o.observe(passwordRef.current, {
      attributes: true,
    });
    o.observe(usernameRef.current, {
      attributes: true,
    });
    return () => {
      o.disconnect();
    };
  }, [setPasswordManagerDetected]);

  useEffect(() => {
    if (passwordManagerDetected.length > 0) {
      setShowEndAdornments(false);
    }
  }, [passwordManagerDetected]);

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    setServerError("");
    const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]");
    const passwordField = document.getElementById("id_password");
    const usernameField = document.getElementById("id_username");
    passwordField.value = password;
    usernameField.value = email;
    const form = document.getElementById("id_form");
    if (csrfToken) {
      form.appendChild(csrfToken);
      form.submit();
    } else {
      console.error("CSRF token not found");
    }
  };

  const handlePasswordReset = async (event) => {
    const handleSuccess = (data) => {
      if (data.detail === "Received password-reset request.") {
        setMode("sent");
        setLoading(false);
      } else {
        setMode("login");
        setLoading(false);
      }
    };
    const handleError = (error) => {
      console.error("Error posting to password-reset:", error.message);
      setMode("login");
      setLoading(false);
    };
    event.preventDefault();
    setLoading(true);
    setServerError("");
    const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]");
    const usernameField = document.getElementById("id_username");
    usernameField.value = email;
    const form = document.getElementById("id_form");
    if (csrfToken) {
      form.appendChild(csrfToken);
      const data = new URLSearchParams(new FormData(form));
      fetch("/user/password-reset/", {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then(handleSuccess)
        .catch(handleError);
    } else {
      console.error("CSRF token not found");
    }
  };

  const handleEmailBlur = (event) => {
    const email = event.target.value;
    if (email.length > 0) {
      setShowEmailClear(true);
    } else {
      setShowEmailClear(false);
    }
    setEmail(email);
    if (isEmail(email)) {
      setEmailValid(true);
      setLocalEmailError("");
    } else {
      setEmailValid(false);
      if (email.length > 0) {
        setLocalEmailError("Please enter a valid email address.");
      }
    }
  };

  const handleEmailChange = (event) => {
    const typedEmail = event.target.value;
    if (typedEmail.length > 0) {
      setShowEmailClear(true);
    } else {
      setShowEmailClear(false);
    }
    // user is modifying email, so don't show error message
    if (typedEmail.length !== email.length) {
      setLocalEmailError("");
    }
    setEmail(typedEmail);
    if (isEmail(typedEmail)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setPassword(password);
    if (password.length > 0) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  const handleClickClearEmail = () => {
    setEmail("");
    setLocalEmailError("");
    setShowEmailClear(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleClickBackToLogin = (event) => {
    setMode("login");
    setServerError("");
  };

  const handleClickPasswordReset = (event) => {
    setMode("reset");
    setServerError("");
  };

  function onKeyDown(e) {
    if (e.isTrusted && e.key !== "Unidentified") {
      setShowEndAdornments(true);
    } else {
      // console.log("keydown - user event");
    }
  }

  // when paste event happens (TODO: do any password managers or browser autofill trigger this event?)
  function onPaste(e) {
    setShowEndAdornments(true);
  }

  // when click event happens
  function onClick(e) {
    if (e.isTrusted) {
      if (isFirstClick && email.length === 0 && password.length === 0) {
        // console.log("first click - user event");
        checkForPasswordManagers();
        setIsFirstClick(false);
      } else {
        // console.log("click - user event");
      }
    } else {
      // console.log("click - not a user event");
      const onePassword = document.querySelector("com-1password-button"); // outside of React tree
      if (onePassword) {
        setPasswordManagerDetected("onepassword");
        setShowEndAdornments(false);
      }
    }
  }

  const TitleComponents = Object.freeze({
    login: (
      <Typography
        variant="h1"
        className={classes.title}
        data-test="header-account-login"
      >
        Account Login
      </Typography>
    ),
    reset: (
      <Typography
        variant="h1"
        className={classes.title}
        data-test="header-reset-password"
      >
        Reset Password
      </Typography>
    ),
    sent: (
      <HorizontalStack spacing={0} align="center">
        <span className={clsx("material-icons", classes.check)}>check</span>
        <Typography
          variant="h1"
          className={classes.title}
          data-test="header-request-sent"
        >
          Request Sent
        </Typography>
      </HorizontalStack>
    ),
  });

  const EndAdornmentComponents = Object.freeze({
    email: (
      <InputAdornment position="end">
        <IconButton
          aria-label="clear email input"
          className={classes.formControlIcon}
          onClick={handleClickClearEmail}
          onMouseDown={handleMouseDown}
          tabIndex={-1}
        >
          {showEmailClear ? (
            <span className="material-icons">cancel</span>
          ) : null}
        </IconButton>
      </InputAdornment>
    ),
    password: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          className={classes.formControlIcon}
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDown}
          tabIndex={-1}
        >
          {password.length === 0 ? null : showPassword ? (
            <span className="material-icons">visibility</span>
          ) : (
            <span className="material-icons">visibility_off</span>
          )}
        </IconButton>
      </InputAdornment>
    ),
  });

  return (
    <form
      action={mode === "reset" ? "/user/password/reset/" : null}
      id="id_form"
      method="POST"
      className={classes.form}
    >
      <VerticalStack align="center" className={classes.loginStack} spacing={8}>
        <Logo />
        <Convenient style={{ transform: "translateX(-6px)" }} />
        <Welcome />
        {TitleComponents[mode]}
        <Fade in={serverError.length > 0}>
          <Alert
            variant="filled"
            severity="error"
            className={classes.errorAlert}
          >
            {serverError}
          </Alert>
        </Fade>
        <Fade in={serverError.length === 0 && localEmailError.length > 0}>
          <Alert
            variant="filled"
            severity="error"
            className={classes.errorAlert}
          >
            {localEmailError}
          </Alert>
        </Fade>
        <input id="id_password" type="hidden" name="password" />
        <input id="id_username" type="hidden" name="username" />
        {(mode === "login" || mode === "reset") && (
          <FormControl
            className={clsx(classes.formControl, {
              [classes.formControlFilled]: email.length > 0,
            })}
            tabIndex={-1}
            variant="filled"
          >
            <InputLabel htmlFor="email-input">Email Address</InputLabel>
            <FilledInput
              endAdornment={
                showEndAdornments ? EndAdornmentComponents.email : null
              }
              id="email-input"
              inputRef={usernameRef}
              onBlur={handleEmailBlur}
              onChange={handleEmailChange}
              onClick={onClick}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
              value={email}
            />
          </FormControl>
        )}
        {mode === "login" && (
          <FormControl
            className={clsx(classes.formControl, {
              [classes.formControlFilled]: password.length > 0,
            })}
            tabIndex={-1}
            variant="filled"
          >
            <InputLabel htmlFor="password-input">Password</InputLabel>
            <FilledInput
              endAdornment={
                showEndAdornments ? EndAdornmentComponents.password : null
              }
              id="password-input"
              inputRef={passwordRef}
              onChange={handlePasswordChange}
              onClick={onClick}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
              type={showPassword ? "text" : "password"}
              value={password}
            />
          </FormControl>
        )}
        {mode === "sent" && (
          <>
            <Typography color="textSecondary" gutterBottom>
              You should see an email with reset instructions within the next 15
              minutes — if a user account for {`${email}`} is found in our
              system.
            </Typography>
            <Box style={{ height: "2vh" }} />
            <Typography color="textSecondary" gutterBottom>
              Remember to check your SPAM folder if you don’t see it.
            </Typography>
            <Box style={{ height: "2vh" }} />
          </>
        )}
        {mode === "reset" && (
          <Button
            classes={{
              root: classes.root,
              disabled: classes.disabled,
              label: classes.label,
            }}
            className={classes.button}
            color="secondary"
            data-test="button-reset-password"
            disabled={!emailValid}
            onClick={handlePasswordReset}
            type="submit"
            variant="contained"
          >
            Reset Password
          </Button>
        )}
        {mode === "login" && (
          <Button
            classes={{
              root: classes.root,
              disabled: classes.disabled,
              label: classes.label,
            }}
            className={classes.button}
            color="secondary"
            data-test="button-login"
            disabled={!emailValid || !passwordValid}
            onClick={handleLogin}
            type="submit"
            variant="contained"
          >
            Login
          </Button>
        )}
        {(mode === "reset" || mode === "sent") && (
          <Typography className={classes.footer} noWrap>
            <Link
              className={classes.backLink}
              component="button"
              onClick={handleClickBackToLogin}
            >
              {mode === "reset" ? "Back" : "Back to Login"}
            </Link>
          </Typography>
        )}
        {mode === "login" && (
          <Typography className={classes.footer} noWrap>
            <Link
              className={classes.externalLink}
              component="button"
              onClick={handleClickPasswordReset}
            >
              Forgot Password?
            </Link>
          </Typography>
        )}
        <Box style={{ height: "2vh" }} />
        <HorizontalStack>
          <DashedLine />
          <Typography variant="h2" className={classes.subtitle}>
            continue with
          </Typography>
          <DashedLine />
        </HorizontalStack>
        <HorizontalStack spacing={128} className={classes.logosStack}>
          <Link href="/login/facebook/?">
            <Facebook />
          </Link>
          <Link href="/login/google-oauth2/?">
            <Google />
          </Link>
          {/* <Link href="/login/apple/?">
          <Apple />
        </Link> */}
        </HorizontalStack>
        <HorizontalStack align="center">
          <Typography className={classes.footer} noWrap>
            Don’t have a FreshLime account?{" "}
            <Link
              className={classes.externalLink}
              href="https://offers.freshlime.com/signup"
              rel="noopener noreferrer"
              target="_blank"
            >
              Sign-Up Free
            </Link>
          </Typography>
        </HorizontalStack>
        <Box flexGrow={1} />
        <HorizontalStack align="center">
          <Typography className={classes.smallPrint} noWrap>
            &copy; FreshLime, Inc &middot; All Rights Reserved
            <Link
              className={classes.externalLink}
              href="https://www.freshlime.com/about/privacy-policy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              className={classes.externalLink}
              href="https://www.freshlime.com/about/terms-conditions/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms
            </Link>
          </Typography>
        </HorizontalStack>
      </VerticalStack>
    </form>
  );
};

export default LoginForm;
