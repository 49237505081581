import { makeStyles } from "@material-ui/core/styles";

const containerWidth = 840;
const outerFormWidth = 420;
const formWidth = 344;
const inputWidth = 328;

// example: lighten(theme...slate, 0.5) yields #67676780
const lighten = (color = "#000000", coefficient = 1) => {
  if (
    typeof color === "string" &&
    color.indexOf("#") === 0 &&
    typeof coefficient === "number"
  ) {
    const count = Math.round(coefficient * 255);
    const hex = count.toString(16);
    return color + hex.toString();
  } else {
    return color;
  }
};

const useStyles = makeStyles((theme) => ({
  // WRAPPING ELEMENTS
  form: {
    height: "100%",
  },
  loginStack: {
    height: "100%",
    minWidth: outerFormWidth,
    maxWidth: containerWidth,
    paddingBottom: 16,
    paddingTop: 76,
    width: formWidth,
  },
  // TYPOGRAPHY
  title: {
    color: theme.palette.spectrum.yale,
    fontSize: 12,
    fontWeight: 400,
    paddingBottom: "1vh",
    paddingTop: "1vh",
    textTransform: "uppercase",
  },
  check: {
    color: theme.palette.spectrum.lime,
    transform: "translateY(-2px)",
  },
  subtitle: {
    color: theme.palette.spectrum.slate,
    fontSize: 12,
    fontWeight: 400,
    textTransform: "uppercase",
  },
  footer: {
    color: theme.palette.spectrum.slate,
    fontSize: 14,
    fontWeight: 400,
    "& $externalLink": {
      fontSize: 12,
    },
    "& $backLink": {
      fontSize: 16,
    },
  },
  smallPrint: {
    color: theme.palette.spectrum.slate,
    fontSize: 8,
    fontWeight: 400,
    "& $externalLink": {
      textDecoration: "underline",
    },
  },
  backLink: {
    color: theme.palette.spectrum.azure,
    textTransform: "uppercase",
  },
  buttonLink: {
    color: theme.palette.spectrum.azure,
    fontSize: 12,
    fontWeight: 400,
    textTransform: "uppercase",
  },
  externalLink: {
    color: theme.palette.spectrum.azure,
    fontWeight: 400,
    marginLeft: "1em",
    textTransform: "uppercase",
  },
  // FORM ELEMENTS
  formControl: {
    paddingBottom: "3vh",
    width: formWidth,
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.spectrum.white,
      "&:hover": {
        backgroundColor: theme.palette.spectrum.ivory,
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: theme.palette.spectrum.ivory,
    },
    // this is user's input
    "& .MuiFilledInput-input": {
      color: theme.palette.spectrum.slate,
      fontWeight: 500,
    },
    "& .MuiFilledInput-underline": {
      color: lighten(theme.palette.spectrum.slate, 0.38),
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: lighten(theme.palette.spectrum.slate, 0.38),
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: lighten(theme.palette.spectrum.slate, 0.38),
    },
    // label when large, where user's input will be
    "& label.MuiInputLabel-root": {
      color: lighten(theme.palette.spectrum.slate, 0.87),
      fontWeight: 500,
    },
    // label when small, above user's input
    "& label.MuiInputLabel-shrink": {
      color: lighten(theme.palette.spectrum.slate, 0.38),
      fontWeight: 500,
    },
  },
  formControlFilled: {
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.spectrum.ivory,
      "&:hover": {
        backgroundColor: theme.palette.spectrum.ivory,
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: theme.palette.spectrum.ivory,
    },
  },
  formControlIcon: {
    color: lighten(theme.palette.spectrum.slate, 0.38),
    "& span": {
      fontSize: 22,
    },
  },
  button: {
    marginBottom: "1vh",
    width: inputWidth,
    "&$label": {
      color: "pink",
    },
  },
  errorAlert: {
    borderRadius: 0,
    width: formWidth,
  },
  // LOGIN button: root, disabled, label
  root: {
    "&$disabled": {
      backgroundColor: theme.palette.spectrum.gray,
      color: theme.palette.spectrum.cloud,
    },
  },
  disabled: {},
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
  // MISC
  forgotBox: {
    paddingBottom: "1vh",
  },
  logosStack: {
    paddingBottom: "2vh",
    paddingTop: "1vh",
  },
}));

export default useStyles;
